

















import { Component, Prop, Vue } from 'vue-property-decorator';
import { ConservatorshipDetails } from '@/modules/conservatorship/types';
import FileManagementMobilePlugin from '@/modules/fileManagement/components/FileManagementMobilePlugin.vue';

const FileManagementPlugin = () => import('@/modules/fileManagement/components/FileManagementPlugin.vue');

@Component({
  components: { FileManagementMobilePlugin, FileManagementPlugin }
})
export default class FileManagementTab extends Vue {
  @Prop({ type: Object }) conservatorship!: ConservatorshipDetails;
}
