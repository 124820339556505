














































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ApiResponse } from '@/components/types';
import {
  FETCH_FILES_BY_FOLDER,
  FETCH_INBOX_BY_CONSERVATORSHIP,
  FETCH_OUTBOX,
  FETCH_TREE_BY_CONSERVATORSHIP,
  GET_FILES_BY_FOLDER,
  GET_INBOX_BY_CONSERVATORSHIP,
  GET_OUTBOX_BY_CONSERVATORSHIP,
  GET_TREE_BY_CONSERVATORSHIP
} from '../store';
import { File, Folder } from '../types';
import format from '@/filter/dateFormat';
import ViewFileDialog from '@/modules/fileManagement/components/ViewFileDialog.vue';
import FileDownload from '@/modules/fileManagement/components/FileDownload.vue';
import PluginCards from '@/modules/fileManagement/components/PluginCards.vue';
import { routeToLocation } from '@/router';

const FileManagement = namespace('fileManagement');

@Component({
  components: { PluginCards, FileDownload, ViewFileDialog }
})
export default class FileManagementMobilePlugin extends Vue {
  @FileManagement.Action(FETCH_TREE_BY_CONSERVATORSHIP) fetchFolder!: (conservatorship: string) => Promise<ApiResponse<Folder[]>>;
  @FileManagement.Getter(GET_TREE_BY_CONSERVATORSHIP) getTree!: (conservatorship: string) => Folder[];

  @FileManagement.Action(FETCH_FILES_BY_FOLDER) fetchFiles!: (folder: string) => Promise<ApiResponse<File[]>>;
  @FileManagement.Getter(GET_FILES_BY_FOLDER) getFiles!: (folder: string) => File[];

  @FileManagement.Action(FETCH_OUTBOX) fetchOutbox!: (conservatorship: string) => Promise<ApiResponse<File[]>>;
  @FileManagement.Getter(GET_OUTBOX_BY_CONSERVATORSHIP) getOutbox!: (conservatorship: string) => File[];

  @FileManagement.Action(FETCH_INBOX_BY_CONSERVATORSHIP) fetchInbox!: (conservatorship: string) => Promise<ApiResponse<File[]>>;
  @FileManagement.Getter(GET_INBOX_BY_CONSERVATORSHIP) getInbox!: (conservatorship: string) => File[];

  @Prop({ type: String }) conservatorship!: string;

  loading: boolean = false;
  tree: Folder[] = [];
  active: string[] = [];
  search: string = '';

  uploadFolderId: string = '';
  viewFile: File | null = null;

  financeFolder = 'Finanz Belege';

  get items(): Array<Folder  & { color: string }> {
    const tree = (this.getTree(this.conservatorship) || [])
      .sort((a ,b) => {
        if (a.name === b.name) {
          return 0;
        }

        if (a.name === this.financeFolder) {
          return -1;
        }

        if (b.name === this.financeFolder) {
          return 1;
        }

        return a.name.localeCompare(b.name)
      })
      .map((folder): Folder & { color: string } => {
        if (folder.name === this.financeFolder) {
          return { ...folder, color: 'success--text' };
        }

        return { ...folder, color: '' };
      })

    return [
      { id: 'inbox', name: 'Posteingang', children: [], files: 0, color: 'warning--text' },
      { id: 'outbox', name: 'Postausgang', children: [], files: 0, color: 'warning--text' },
      ...tree
    ];
  }

  get folder() {
    if (this.active.length === 0) return null;

    return this.active[0];
  }

  get files() {
    if (!this.folder) return [];

    if (this.folder === 'outbox') {
      return this.getOutbox(this.conservatorship).map((file: File) => ({
        ...file,
        createdTrans: format(file.created, 'dd.MM.yyyy HH:mm')
      }));
    }

    if (this.folder === 'inbox') {
      return this.getInbox(this.conservatorship).map((file: File) => ({
        ...file,
        createdTrans: format(file.created, 'dd.MM.yyyy HH:mm')
      }));
    }

    return this.getFiles(this.folder).map((file: File) => ({
      ...file,
      createdTrans: format(file.created, 'dd.MM.yyyy HH:mm')
    }));
  }

  get openFolder(): string {
    return this.$route.query['folder'] as string;
  }

  set openFolder(folder: string) {
    if (!folder) {
      const { folder, ...query } = this.$route.query;
      this.$router.push(routeToLocation({ ...this.$route, query: { ...query } })).catch(() => {});
      return;
    }

    this.$router.push(routeToLocation({ ...this.$route, query: { ...this.$route.query, folder } })).catch(() => {});
  }

  get financeFolderId(): string | null {
    return (this.getTree(this.conservatorship).find((folder) => folder.name === this.financeFolder) || { id: null }).id;
  }

  hasUpload(folder: Folder) {
    if (['outbox', 'inbox'].includes(folder.id)) {
      return false;
    }

    if (folder.id === this.financeFolderId) {
      return false;
    }

    if (folder.rootId === this.financeFolderId) {
      return false;
    }

    return true
  }

  @Watch('active', { deep: true })
  async loadFiles([folder]: string[]) {
    this.openFolder = folder;

    if (!folder) return;

    if (folder === 'outbox') {
      return await this.fetchOutbox(this.conservatorship);
    }

    if (folder === 'inbox') {
      return await this.fetchInbox(this.conservatorship);
    }

    await this.fetchFiles(folder);
  }

  @Watch('conservatorship', { immediate: true })
  async loadFolders(conservatorship: string) {
    this.loading = true;

    await this.fetchFolder(conservatorship);

    this.loading = false;

    if (this.active[0] !== this.openFolder) {
      this.active = [this.openFolder];
    }
  }

  uploadTo(folder: Folder) {
    this.uploadFolderId = folder.id;
    this.active.push(folder.id)
  }
}
