






































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { File, MimeType } from '../types';
import AppTable from '@/components/AppTable.vue';
import FileDownload from './FileDownload.vue';
import CardRow from '@/components/CardRow.vue';
import AppIterator from '@/components/AppIterator.vue';
import CardHeader from '@/components/CardHeader.vue';

const FileManagement = namespace('fileManagement');

@Component({
  components: { CardHeader, AppIterator, CardRow, FileDownload, AppTable }
})
export default class FileManagementPlugin extends Vue {
  @Prop({ type: Array, default: () => [] }) files!: File | undefined;
  @Prop({ type: Object }) renameFile!: File | undefined;
  @Prop({ type: Object }) moveFile!: File | undefined;
  @Prop({ type: Object }) deleteFile!: File | undefined;
  @Prop({ type: Object }) viewFile!: File | undefined;

  pagination = {
    sortBy: 'filename',
    itemsPerPage: -1
  };

  search: string = '';

  get rename() {
    return this.renameFile;
  }

  set rename(value) {
    this.$emit('update:renameFile', value);
  }

  get move() {
    return this.moveFile;
  }

  set move(value) {
    this.$emit('update:moveFile', value);
  }

  get deleted() {
    return this.deleteFile;
  }

  set deleted(value) {
    this.$emit('update:deleteFile', value);
  }

  get view() {
    return this.viewFile;
  }

  set view(value) {
    this.$emit('update:viewFile', value);
  }

  supportedViews: MimeType[] = [
    MimeType.JPG,
    MimeType.PDF,
    MimeType.PNG
  ];
}
