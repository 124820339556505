var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{staticClass:"mb-3"},[_c('v-toolbar',{staticClass:"transparent",attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Dateiverwaltung")]),_c('v-spacer')],1),_c('v-divider'),(_vm.tree)?_c('v-container',{attrs:{"fluid":""}},[_c('v-layout',[_c('v-flex',{attrs:{"xs12":""}},[_c('v-treeview',{attrs:{"items":_vm.items,"activatable":"","item-key":"id","active":_vm.active,"open-all":""},on:{"update:active":function($event){_vm.active=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [_c('v-icon',{class:item.color},[_vm._v(" "+_vm._s(open ? 'mdi-folder-open' : 'mdi-folder')+" ")])]}},{key:"label",fn:function(ref){
var item = ref.item;
return [_c('div',{class:item.color,staticStyle:{"user-select":"none"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"append",fn:function(ref){
var item = ref.item;
return [(_vm.hasUpload(item))?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.uploadTo(item)}}},[_c('v-icon',[_vm._v("add")])],1):_vm._e()]}}],null,false,3018897816),model:{value:(_vm.tree),callback:function ($$v) {_vm.tree=$$v},expression:"tree"}})],1)],1)],1):_vm._e()],1),_c('plugin-cards',{key:_vm.folder,attrs:{"view-file":_vm.viewFile,"files":_vm.files},on:{"update:viewFile":function($event){_vm.viewFile=$event},"update:view-file":function($event){_vm.viewFile=$event}}}),_c('view-file-dialog',{attrs:{"file":_vm.viewFile},on:{"update:file":function($event){_vm.viewFile=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }